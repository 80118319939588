import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent } from './ui/card';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Building, Users, Medal, ArrowUpDown } from 'lucide-react';
import { createClient } from '@supabase/supabase-js';

// Get Supabase URL and anon key from environment variables
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

// Throw an error if variables are not defined
if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL and anon key are required');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

const ElectionResultsSection = ({ electionId }) => {
  const [electionData, setElectionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentRoundId, setCurrentRoundId] = useState(null);
  const [totalPollingStations, setTotalPollingStations] = useState(22);
  const [communitySortOrder, setCommunitySortOrder] = useState('alphabetical'); // 'alphabetical' or 'votes'

  const transformVoteData = useCallback((voteData) => {
    const candidateVotes = {};
    let totalVoters = 0;

    voteData.forEach((vote) => {
      const candidateId = vote.round_candidates.candidate_id;
      const communityName = vote.communities.name;
      const voteCount = vote.votes;
      totalVoters += voteCount;

      if (!candidateVotes[candidateId]) {
        candidateVotes[candidateId] = {
          candidate_id: candidateId,
          full_name: vote.round_candidates.candidates.full_name,
          total_votes: 0,
          communities: [],
        };
      }

      candidateVotes[candidateId].total_votes += voteCount;
      candidateVotes[candidateId].communities.push({
        name: communityName,
        votes: voteCount,
      });
    });

    return {
      totalVoters,
      results: Object.values(candidateVotes),
    };
  }, []);

  const fetchElectionData = useCallback(async () => {
    if (!electionId) return;

    try {
      setLoading(true);

      const { data: roundData, error: roundError } = await supabase
        .from('election_rounds')
        .select('id')
        .eq('election_id', electionId)
        .eq('status', 'active')
        .single();

      if (roundError) throw roundError;

      const roundId = roundData.id;
      setCurrentRoundId(roundId);

      const { count: communitiesCount, error: communitiesError } = await supabase
        .from('communities')
        .select('*', { count: 'exact', head: true });

      if (communitiesError) throw communitiesError;
      setTotalPollingStations(communitiesCount || 0);

      const { data: voteData, error: voteError } = await supabase
        .from('vote_results')
        .select(`
          votes,
          communities (
            id,
            name
          ),
          round_candidates!inner (
            candidate_id,
            candidates (
              id,
              full_name
            )
          )
        `)
        .eq('round_id', roundId);

      if (voteError) throw voteError;

      const transformedData = transformVoteData(voteData || []);
      setElectionData(transformedData);
    } catch (err) {
      console.error('Error fetching election data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [electionId, transformVoteData]);

  useEffect(() => {
    fetchElectionData();
  }, [fetchElectionData]);

  useEffect(() => {
    if (!currentRoundId) return;

    const voteChannel = supabase.channel('vote-results-channel');

    voteChannel
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'vote_results',
          filter: `round_id=eq.${currentRoundId}`,
        },
        async (payload) => {
          console.log('Received real-time update:', payload);
          await fetchElectionData();
        }
      )
      .subscribe();

    voteChannel.on('status', ({ status }) => {
      console.log('Subscription status:', status);
    });

    return () => {
      supabase.removeChannel(voteChannel);
    };
  }, [currentRoundId, fetchElectionData]);

  const getCommunityTotalVotes = (community) => {
    return electionData.results.reduce((total, candidate) => {
      const communityResult = candidate.communities.find(c => c.name === community.name);
      return total + (communityResult?.votes || 0);
    }, 0);
  };

  const getSortedCommunities = (communities) => {
    if (!communities) return [];
    
    return [...communities].sort((a, b) => {
      if (communitySortOrder === 'votes') {
        const totalVotesA = getCommunityTotalVotes(a);
        const totalVotesB = getCommunityTotalVotes(b);
        return totalVotesB - totalVotesA;
      }
      return a.name.localeCompare(b.name);
    });
  };

  const calculatePercentage = (votes) => {
    if (!electionData?.totalVoters) return '0.0';
    return ((votes / electionData.totalVoters) * 100).toFixed(1);
  };

  const getLeadingCandidateDisplay = () => {
    if (!electionData?.results || electionData.results.length === 0) {
      return 'No results';
    }

    const sortedCandidates = [...electionData.results].sort(
      (a, b) => b.total_votes - a.total_votes
    );

    if (sortedCandidates[0].total_votes === 0) {
      return 'No votes cast';
    }

    if (
      sortedCandidates.length > 1 &&
      sortedCandidates[0].total_votes === sortedCandidates[1].total_votes
    ) {
      return 'Tied';
    }

    return sortedCandidates[0].full_name;
  };

  const determineLeadingCandidate = (results) => {
    if (!results || results.length === 0) {
      return { full_name: 'None', total_votes: 0 };
    }

    const sortedResults = [...results].sort((a, b) => b.total_votes - a.total_votes);

    if (sortedResults[0].total_votes === 0) {
      return { full_name: 'None', total_votes: 0 };
    }

    if (
      sortedResults.length > 1 &&
      sortedResults[0].total_votes === sortedResults[1].total_votes
    ) {
      return { full_name: 'None - Tied', total_votes: sortedResults[0].total_votes };
    }

    return sortedResults[0];
  };

  const getProgressCircleStyle = (percentage) => ({
    background: `conic-gradient(#176e8a ${percentage * 3.6}deg, #e9ecef 0deg)`,
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <p className="text-lg text-gray-600">Loading election results...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <p className="text-lg text-red-600">Error loading results: {error}</p>
      </div>
    );
  }

  if (!electionData) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <p className="text-lg text-gray-600">No election data available</p>
      </div>
    );
  }

  const leadingCandidate = determineLeadingCandidate(electionData.results);
  const communitiesReporting = electionData.results[0]?.communities.length || 0;
  const communitiesReportingPercentage = (
    (communitiesReporting / totalPollingStations) *
    100
  ).toFixed(1);

  return (
    <section className="bg-gray-50 py-16" id="results">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold mb-4">
            CHB Chairperson <br />
            <span className="text-[#176e8a]">Election Results</span>
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Results are being updated in real-time as they are received from each community.
            These results are unofficial until certified by the Returning Officer.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 text-center">
          <div>
            <div className="relative w-48 h-48 mx-auto mb-4">
              <div
                className="w-full h-full rounded-full flex items-center justify-center relative overflow-hidden"
                style={getProgressCircleStyle(calculatePercentage(leadingCandidate.total_votes))}
              >
                <div className="absolute inset-2 bg-white rounded-full flex items-center justify-center">
                  <div>
                    <Medal className="w-8 h-8 text-[#176e8a] mx-auto mb-2" />
                    <span className="text-2xl font-bold">
                      {calculatePercentage(leadingCandidate.total_votes)}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-lg font-semibold text-gray-800">Leading Candidate</h5>
            <p className="text-[#176e8a]">{getLeadingCandidateDisplay()}</p>
          </div>

          <div>
            <div className="relative w-48 h-48 mx-auto mb-4">
              <div
                className="w-full h-full rounded-full flex items-center justify-center relative overflow-hidden"
                style={getProgressCircleStyle(100)}
              >
                <div className="absolute inset-2 bg-white rounded-full flex items-center justify-center">
                  <div>
                    <Users className="w-8 h-8 text-[#176e8a] mx-auto mb-2" />
                    <span className="text-2xl font-bold">{electionData.totalVoters}</span>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-lg font-semibold text-gray-800">Total Votes Cast</h5>
            <p className="text-[#176e8a]">Across All Communities</p>
          </div>

          <div>
            <div className="relative w-48 h-48 mx-auto mb-4">
              <div
                className="w-full h-full rounded-full flex items-center justify-center relative overflow-hidden"
                style={getProgressCircleStyle(communitiesReportingPercentage)}
              >
                <div className="absolute inset-2 bg-white rounded-full flex items-center justify-center">
                  <div>
                    <Building className="w-8 h-8 text-[#176e8a] mx-auto mb-2" />
                    <span className="text-2xl font-bold">
                      {communitiesReporting}/{totalPollingStations}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h5 className="text-lg font-semibold text-gray-800">Communities Reporting</h5>
            <p className="text-[#176e8a]">{communitiesReportingPercentage}% Complete</p>
          </div>
        </div>

        <div className="mt-16">
          <Card className="bg-white shadow-lg">
            <CardContent className="p-6">
              <Tabs defaultValue="overall" className="w-full">
                <TabsList className="grid w-full grid-cols-2 mb-8">
                  <TabsTrigger value="overall">Overall Results</TabsTrigger>
                  <TabsTrigger value="by-community">Results by Community</TabsTrigger>
                </TabsList>

                <TabsContent value="overall">
                  <div className="overflow-x-auto">
                    <table className="w-full">
                      <thead>
                        <tr className="border-b">
                          <th className="text-left py-3 px-4">Candidate</th>
                          <th className="text-right py-3 px-4">Votes</th>
                          <th className="text-right py-3 px-4">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {electionData.results.map((candidate) => (
                          <tr key={candidate.candidate_id} className="border-b">
                            <td className="py-3 px-4">{candidate.full_name}</td>
                            <td className="text-right py-3 px-4">{candidate.total_votes}</td>
                            <td className="text-right py-3 px-4">
                              {calculatePercentage(candidate.total_votes)}%
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </TabsContent>
                
                <TabsContent value="by-community">
                <div className="flex justify-end mb-4">
                  <button
                    onClick={() => setCommunitySortOrder(prev => 
                      prev === 'alphabetical' ? 'votes' : 'alphabetical'
                    )}
                    className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
                  >
                    <ArrowUpDown className="w-4 h-4" />
                    Sort by {communitySortOrder === 'alphabetical' ? 'Total Votes' : 'Community Name'}
                  </button>
                </div>
                <div className="space-y-6">
                  {getSortedCommunities(electionData.results[0]?.communities).map((community) => {
                    const communityTotalVotes = getCommunityTotalVotes(community);
                    
                    return (
                      <div key={community.name} className="border-b pb-6">
                        <div className="flex justify-between items-center mb-4">
                          <h3 className="font-semibold text-lg">{community.name}</h3>
                          <span className="text-gray-600">Total Votes: {communityTotalVotes}</span>
                        </div>
                        <table className="w-full">
                          <thead>
                            <tr>
                              <th className="text-left py-2 px-4">Candidate</th>
                              <th className="text-right py-2 px-4">Votes</th>
                              <th className="text-right py-2 px-4">% of Community</th>
                            </tr>
                          </thead>
                          <tbody>
                            {electionData.results.map((candidate) => {
                              const communityResult = candidate.communities.find(
                                (c) => c.name === community.name
                              );
                              const votes = communityResult?.votes || 0;
                              const percentage = communityTotalVotes > 0 
                                ? ((votes / communityTotalVotes) * 100).toFixed(1) 
                                : '0.0';
                              
                              return (
                                <tr key={candidate.candidate_id}>
                                  <td className="py-2 px-4">{candidate.full_name}</td>
                                  <td className="text-right py-2 px-4">{votes}</td>
                                  <td className="text-right py-2 px-4">{percentage}%</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    );
                  })}
                </div>
              </TabsContent>
              </Tabs>
            </CardContent>
          </Card>
        </div>
      </div>
    </section>
  );
};

export default ElectionResultsSection;
